import * as React from 'react';
import { ILinks4Data } from '../absolute-ff.props.autogenerated';

export interface IContentCardLinks4 {
    links4: ILinks4Data[];
}

const ContentCardLinks4: React.FC<IContentCardLinks4> = ({ links4 }) => (
    <div className='ms-content-block__cta'>
        {links4.map((cta: ILinks4Data, index: number) => {
            const btnClass = index === 0 ? 'msc-cta__primary' : 'msc-cta__secondary';
            return <a
                key={`link-${index}`}
                className={btnClass}
                role='button'
                title={cta.linkText}
                href={cta.linkUrl && cta.linkUrl.destinationUrl}
                aria-label={cta.ariaLabel}
                target={cta.openInNewTab ? '_blank' : undefined}
            >
                {cta.linkText}
            </a>;
        })}
    </div>
);

export default ContentCardLinks4;