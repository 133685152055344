import * as React from 'react';
import { ILinks1Data } from '../obrien-ff.props.autogenerated';

export interface IContentCardLinks1 {
    links1: ILinks1Data[];
}

const ContentCardLinks1: React.FC<IContentCardLinks1> = ({ links1 }) => (
    <div className='ms-content-block__cta'>
        {links1.map((cta: ILinks1Data, index: number) => {
            const btnClass = index === 0 ? 'msc-cta__primary' : 'msc-cta__secondary';
            return <a
                key={`link-${index}`}
                className={btnClass}
                role='button'
                title={cta.linkText}
                href={cta.linkUrl && cta.linkUrl.destinationUrl}
                aria-label={cta.ariaLabel}
                target={cta.openInNewTab ? '_blank' : undefined}
            >
                <span>{cta.linkText}</span>
            </a>;
        })}
    </div>
);

export default ContentCardLinks1;