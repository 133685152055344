import * as React from 'react';
import { ILinks8Data } from '../obrien-ff.props.autogenerated';

export interface IContentCardLinks8 {
    links8: ILinks8Data[];
}

const ContentCardLinks8: React.FC<IContentCardLinks8> = ({ links8 }) => (
    <div className='ms-content-block__cta'>
        {links8.map((cta: ILinks8Data, index: number) => {
            const btnClass = index === 0 ? 'msc-cta__primary' : 'msc-cta__secondary';
            return <a
                key={`link-${index}`}
                className={btnClass}
                role='button'
                title={cta.linkText}
                href={cta.linkUrl && cta.linkUrl.destinationUrl}
                aria-label={cta.ariaLabel}
                target={cta.openInNewTab ? '_blank' : undefined}
            >
                <span>{cta.linkText}</span>
            </a>;
        })}
    </div>
);

export default ContentCardLinks8;